import React from "react";
import theme from "theme";
import { Theme, Text, Section, Button, Image, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				PowerFlex Gym
			</title>
			<meta name={"description"} content={"Wir stärken Ihre Fitness"} />
			<meta property={"og:title"} content={"PowerFlex Gym"} />
			<meta property={"og:description"} content={"Wir stärken Ihre Fitness"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/668fb620fdcae00021e48116/images/1-3.jpg?v=2024-07-11T12:43:06.324Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/668fb620fdcae00021e48116/images/1-3.jpg?v=2024-07-11T12:43:06.324Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/668fb620fdcae00021e48116/images/1-3.jpg?v=2024-07-11T12:43:06.324Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/668fb620fdcae00021e48116/images/1-3.jpg?v=2024-07-11T12:43:06.324Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/668fb620fdcae00021e48116/images/1-3.jpg?v=2024-07-11T12:43:06.324Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/668fb620fdcae00021e48116/images/1-3.jpg?v=2024-07-11T12:43:06.324Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/668fb620fdcae00021e48116/images/1-3.jpg?v=2024-07-11T12:43:06.324Z"} />
		</Helmet>
		<Components.Header2>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Header2>
		<Components.Header>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text4" />
			<Override slot="text5" />
			<Override slot="text6" />
			<Override slot="box3" />
			<Override slot="box2" />
			<Override slot="box1" />
			<Override slot="box" />
		</Components.Header>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/668fb620fdcae00021e48116/images/2-4%20%281%29.jpg?v=2024-07-11T12:43:06.488Z) center center/cover"
			padding="120px 0 120px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
			height="100vh"
		>
			<Override slot="SectionContent" align-self="center" />
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text
						as="h1"
						font="--headline1"
						md-font="--headline2"
						margin="10px 0"
						color="--light"
						border-color="--color-light"
					>
						Wir stärken Ihre Fitness!
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0" background="--color-light">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline2"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				Willkommen im PowerFlex Gym
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Hier ist Ihr neues Zuhause für Fitness und Gesundheit! Egal, ob Sie Ihre Fitnessreise beginnen oder sich selbst an neue Grenzen bringen möchten, das PowerFlex Gym bietet eine dynamische Umgebung, die Ihre Fitnessziele unterstützt. Unsere hochmoderne Einrichtung und unser engagiertes Team sorgen dafür, dass jedes Training Sie zum Erfolg führt.
			</Text>
			<Button
				padding="15px 44px 15px 44px"
				background="--color-darkL1"
				border-color="rgba(236, 255, 0, 0.3)"
				box-shadow="5px 5px 3px 0 rgba(219, 204, 27, 0.69)"
				font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
				type="link"
				text-decoration-line="initial"
				href="/services"
			>
				Unsere Dienstleistungen
			</Button>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Cards-3">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 1.5rem 0px"
				font="normal 600 64px/1.2 --fontFamily-sans"
				text-align="center"
				md-margin="0px 0px 40px 0px"
				sm-font="normal 600 42px/1.2 --fontFamily-sans"
			>
				Über uns
			</Text>
			<Text margin="0px 0px 2rem 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
				Bei PowerFlex Gym glauben wir an die Kraft einer starken Gemeinschaft, die individuellen Erfolg inspiriert. Unser geräumiges, modernes Fitnessstudio ist mit der neuesten Fitnesstechnologie ausgestattet und bietet eine große Auswahl an Geräten für alle Arten von Training – von Krafttraining und Cardio bis hin zu Flexibilitätsübungen und mehr. Unsere zertifizierten Trainer sind leidenschaftlich an Ihrer Fitness interessiert und führen Sie durch personalisierte Trainingsprogramme.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 34px"
				margin="0px 0px 60px 0px"
				md-grid-template-columns="1fr"
				md-grid-gap="46px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					margin="50px 0px 0px 0px"
					md-margin="0 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/668fb620fdcae00021e48116/images/1-1.jpg?v=2024-07-11T12:43:06.445Z"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/668fb620fdcae00021e48116/images/1-1.jpg?v=2024-07-11T12%3A43%3A06.445Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668fb620fdcae00021e48116/images/1-1.jpg?v=2024-07-11T12%3A43%3A06.445Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668fb620fdcae00021e48116/images/1-1.jpg?v=2024-07-11T12%3A43%3A06.445Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668fb620fdcae00021e48116/images/1-1.jpg?v=2024-07-11T12%3A43%3A06.445Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668fb620fdcae00021e48116/images/1-1.jpg?v=2024-07-11T12%3A43%3A06.445Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668fb620fdcae00021e48116/images/1-1.jpg?v=2024-07-11T12%3A43%3A06.445Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668fb620fdcae00021e48116/images/1-1.jpg?v=2024-07-11T12%3A43%3A06.445Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#dcfc96"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
							Hochmoderne Einrichtungen
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
						Genießen Sie unsere erstklassigen Geräte und gepflegten Einrichtungen, die für optimales Training konzipiert sind.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Image
						src="https://uploads.quarkly.io/668fb620fdcae00021e48116/images/1-5.jpg?v=2024-07-11T12:43:06.419Z"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/668fb620fdcae00021e48116/images/1-5.jpg?v=2024-07-11T12%3A43%3A06.419Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668fb620fdcae00021e48116/images/1-5.jpg?v=2024-07-11T12%3A43%3A06.419Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668fb620fdcae00021e48116/images/1-5.jpg?v=2024-07-11T12%3A43%3A06.419Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668fb620fdcae00021e48116/images/1-5.jpg?v=2024-07-11T12%3A43%3A06.419Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668fb620fdcae00021e48116/images/1-5.jpg?v=2024-07-11T12%3A43%3A06.419Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668fb620fdcae00021e48116/images/1-5.jpg?v=2024-07-11T12%3A43%3A06.419Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668fb620fdcae00021e48116/images/1-5.jpg?v=2024-07-11T12%3A43%3A06.419Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#fced96"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
							Expertenberatung
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
						Unsere professionellen Trainer kümmern sich individuell um Sie, damit Sie Ihre Fitnessziele effizient erreichen.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					margin="80px 0px 0px 0px"
					md-margin="0 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/668fb620fdcae00021e48116/images/1-2.jpg?v=2024-07-11T12:43:06.371Z"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/668fb620fdcae00021e48116/images/1-2.jpg?v=2024-07-11T12%3A43%3A06.371Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668fb620fdcae00021e48116/images/1-2.jpg?v=2024-07-11T12%3A43%3A06.371Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668fb620fdcae00021e48116/images/1-2.jpg?v=2024-07-11T12%3A43%3A06.371Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668fb620fdcae00021e48116/images/1-2.jpg?v=2024-07-11T12%3A43%3A06.371Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668fb620fdcae00021e48116/images/1-2.jpg?v=2024-07-11T12%3A43%3A06.371Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668fb620fdcae00021e48116/images/1-2.jpg?v=2024-07-11T12%3A43%3A06.371Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668fb620fdcae00021e48116/images/1-2.jpg?v=2024-07-11T12%3A43%3A06.371Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#c5fcff"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="normal 500 28px/1.2 --fontFamily-sans">
							Flexible Mitgliedschaften
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#676e75">
						Wir bieten eine Vielzahl von Mitgliedschaftsplänen, die zu Ihrem Lebensstil und Budget passen, ohne versteckte Gebühren.
					</Text>
				</Box>
			</Box>
			<Text margin="0px 0px 2rem 0px" font="--headline3" color="--darkL1">
				Sind Sie bereit, Ihre Fitnessziele in die Realität umzusetzen? Treten Sie PowerFlex Gym noch heute bei! Besuchen Sie uns oder kontaktieren Sie unser Support-Team, um Ihre Reise zu beginnen. Steigern Sie Ihre Fitness mit PowerFlex Gym: Ihre Gesundheit, Ihre Kraft, Ihr Fitnessstudio!
			</Text>
			<Text margin="0px 0px 2rem 0px" font="--headline2" color="--darkL1">
				Treten Sie uns noch heute bei!
			</Text>
			<Button
				padding="15px 44px 15px 44px"
				background="--color-darkL1"
				border-color="rgba(236, 255, 0, 0.3)"
				box-shadow="5px 5px 3px 0 rgba(219, 204, 27, 0.69)"
				font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
				type="link"
				text-decoration-line="initial"
				href="/services"
			>
				Erfahren Sie mehr
			</Button>
		</Section>
		<Components.Footer>
			<Override slot="link3" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link4" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b7ae60009abe001f027d1c"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});